import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import MuiSelect from '@material-ui/core/Select'
import { ArrowForwardIosRounded } from '@material-ui/icons'

import './select.scss'
import { withStyles } from '@material-ui/core'

type TInputProps = {
    value: string[];
    options: string[];
    customValue: string;
    placeholder?: string;
    onChange(value: string[]): void;
    onCustomValueChange(value: string): void;
}

const SelectInput = withStyles({
    selectMenu: { borderRadius: '20px' },
    select: {
        '& ul': {
            backgroundColor: '#cccccc',
        },
        '& li': {
            fontSize: 12,
        },
    },
})(MuiSelect)

const Select = (props: TInputProps) => {
    const { onChange, value, options, customValue, onCustomValueChange, placeholder } = props

    const handleValueRender = (v: unknown) => {
        const valuesArray = (v as string[]).map(v => v === 'customValue' ? customValue : v)
        return valuesArray.join(', ')
    }

    return (
        <FormControl className={`select__wrapper ${!value.length && 'select__placeholder'}`}>
            <SelectInput
                placeholder={placeholder}
                MenuProps={{
                    classes: { list: 'select__list', paper: 'select__paper' },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                IconComponent={ArrowForwardIosRounded}
                multiple
                onChange={(e) => onChange(e.target.value as string[])}
                value={value}
                renderValue={handleValueRender}
            >
                {options.map((option) => (
                    <MenuItem className="select__menu-item" key={option} value={option} onKeyDown={e => e.stopPropagation()}>
                        <ListItemIcon>
                            <Checkbox
                                icon={<span className="select__icon" />}
                                checkedIcon={<span className="select__icon select__icon-checked" />}
                                checked={value.indexOf(option) > -1}
                            />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
                <MenuItem className="select__menu-item" value="customValue"
                          onClick={e => e.stopPropagation()}
                          onKeyDown={e => e.stopPropagation()}>
                    <ListItemIcon                         onKeyDown={e => e.stopPropagation()}
                    >
                        <Checkbox
                            icon={<span className="select__icon" />}
                            checkedIcon={<span className="select__icon select__icon-checked" />}
                            checked={value.indexOf('customValue') > -1}
                        />
                    </ListItemIcon>
                    <input
                        className="select__input"
                        type="text"
                        placeholder="Enter my option"
                        value={customValue}
                        onClick={e => e.stopPropagation()}
                        onChange={(e) => onCustomValueChange(e.target.value)}
                    />
                </MenuItem>
            </SelectInput>
        </FormControl>
    )
}

export default Select
