import React from 'react'
import Header from '../components/Header'
import Content from '../components/Content'
import Form from '../components/Form'
import { Box } from '@material-ui/core'
import SocialBlock from '../components/SocialBlock'

const MainPage = () => (
    <div className="main-page">
        <Header />
        <div className="main-page__content">
            <Content />
            <Form />
            <Box display="flex" className="footer">
                <SocialBlock />
            </Box>
        </div>
    </div>
)

export default MainPage
