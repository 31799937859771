import React from 'react'

import './App.scss'
import { Route, Routes } from 'react-router-dom'
import MainPage from './Pages/Main'
import ThankYouPage from './Pages/ThankYou'
import Unsubscribe from './Pages/Unsubscribe'

function App() {
    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="thank-you" element={<ThankYouPage />} />
            <Route path="unsubscribe" element={<Unsubscribe />} />
        </Routes>
    )
}

export default App
