import React from 'react'
import { useSearchParams } from 'react-router-dom'
import SocialBlock from '../components/SocialBlock'
import Logo from '../components/Logo'
import { Box } from '@material-ui/core'
import { unsubscribeUser } from '../store/actions/userActions'
import { connect } from 'react-redux'

type Props = {
    unsubscribeUser: (email: string) => Promise<{ status: number; message: any; }>
}

const ThankYou = ({ unsubscribeUser }: Props) => {
    const [searchParams] = useSearchParams()
    const email = searchParams.get('email')

    if (email) {
        unsubscribeUser(email)
    }

    return (
        <div className="thank-page">
            <Box mb={8}>
                <Logo />
            </Box>
            <h1 className="text-center thank-page__title">Sorry to see you go!</h1>
            {email && <p className="text-center">{email} unsubscribed successfully.</p>}
            <SocialBlock />
        </div>
    )
}

const mapDispatchToProps = { unsubscribeUser }

export default connect(null, mapDispatchToProps)(ThankYou)
