import React from 'react';

import './SocialBlock.scss';
import SocialButton, {ESocialButtonType} from '../SocialButton/SocialButton';
import {Box} from '@material-ui/core';

const socialButtons = [
  {
    url: 'https://www.linkedin.com/company/artistsangels/',
    type: ESocialButtonType.LINKEDIN,
  },
  {
    url: 'https://www.facebook.com/artistsangels/?modal=admin_todo_tour',
    type: ESocialButtonType.FACEBOOK,
  },
  {
    url: 'https://www.instagram.com/artists_angels/',
    type: ESocialButtonType.INSTAGRAM,
  },
  {
    url: 'https://www.youtube.com/channel/UCTIZ2cNSfTTBfGo03UlfFUg',
    type: ESocialButtonType.YOUTUBE,
  },
];

const SocialBlock = () => {
  const title = 'Warm up your heart by staying in tune with all our social media by clicking the buttons below';

  return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <div className="text-normal share-block__title">{title}</div>
        <Box display="flex" mb={2}>
          {socialButtons.map(b => <SocialButton key={b.type} url={b.url} type={b.type}/>)}
        </Box>
      </Box>
  )
}

export default SocialBlock;
