import React from 'react'
import SocialBlock from '../components/SocialBlock'
import Logo from '../components/Logo'
import { Box } from '@material-ui/core'

const ThankYou = () => (
    <div className="thank-page">
        <Box mb={8}>
            <Logo />
        </Box>
        <h1 className="text-center thank-page__title">Thank you for signing up!</h1>
        <SocialBlock />
    </div>
)

export default ThankYou
