import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core'

import Input from '../Input'
import Select from '../Select'
import { saveUserdata, TFormData } from '../../store/actions/userActions'
import RowRadioButtonsGroup from '../RadioButtons/radio-buttons'
import { selectOptions, radioOptions } from './constants'

import './form.scss'

export type TType = 'Teach' | 'Learn'

type TFormProps = {
    userSubscribed: boolean
    saveUserdata: (data: TFormData) => unknown
}

type TSubscriptionResponse = { status: number, message: string }

const Form = (props: TFormProps) => {
    const { saveUserdata } = props
    const [email, setEmail] = useState('')
    const [customValue, setCustomValue] = useState('')
    const [art, setArt] = useState<Array<string>>([])
    const [type, setType] = useState<TType>('Teach')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useNavigate()
    const emailField = useRef<HTMLInputElement>(null);

    const handleButtonClick = async () => {
        setLoading(true)

        if (!emailField.current?.validity.valid) {
            setError('Invalid email address.')
            setLoading(false)
            return;
        }

        if (!email || !art.length) {
            setError('Please fill in all the fields.')
            setLoading(false)
            return;
        }

        setError('')

        const arts = art.join(', ').replace('customValue', customValue)
        const response = await saveUserdata({ arts, email, type }) as TSubscriptionResponse

        if (response.status === 404) {
            if (response.message.includes('Duplicate entry')) {
                setError(`You have already signed up with ${email}. Check your mailbox or use another email.`);
            } else {
                setError(response.message)
            }
            setLoading(false)
            return;
        }

        setLoading(false)
        history('/thank-you')
    }

    return (
        <div className="form">
            <Box className="form__title text-center">Sign up for your updates. Hit the button below.</Box>
            <div className="form__content">
                <Box className="form__email-wrap">
                    <Input
                        reference={emailField}
                        placeholder="name@example.com"
                        value={email}
                        type="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        label="Email"
                    />
                </Box>
                <Box mb={2} display="flex" className="form__radio">
                    <Box mr={3} className="form__radio-title">I want to</Box>
                    <Box className="form__radio-row">
                        <RowRadioButtonsGroup
                            value={type}
                            options={radioOptions}
                            onChange={(e) => setType(e.target.value as TType)} />
                    </Box>
                </Box>
                <Box className="form__select-wrap">
                    <Select
                        placeholder="Kind of Art"
                        onCustomValueChange={setCustomValue}
                        customValue={customValue}
                        options={selectOptions}
                        value={art}
                        onChange={(e: string[]) => setArt(e)}
                    />
                </Box>
                <button disabled={loading} onClick={handleButtonClick} className="form__button">Sign up</button>
                {error && <div className="form__error text-center">{error}</div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state: { user: { userSubscribed: boolean } }) => ({
    userSubscribed: state.user.userSubscribed,
});

const mapDispatchToProps = { saveUserdata }

export default connect(mapStateToProps, mapDispatchToProps)(Form);