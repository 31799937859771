import React from 'react'
import logo from '../../images/logo.png';

import './header.scss';
import { useMediaQuery } from '@material-ui/core'

const Header = () => {
    const isMobile = useMediaQuery('(max-width: 576px)');
    const content = isMobile
        ? 'is a 5D gamification app where you can learn any art, share your talent with the world and connect with your creative community'
        : ' is a 5D platform where you can learn any art or artistic skill, share your talent and passion with the world and connect with your creative community';

    return (
        <div className="header">
            <div className="header__content text-center">
                <img src={logo} alt="A&A" className="logo"/>
                <div className="logo__title">ARTISTS&ANGELS</div>
                <p className="header__description">{content}</p>
            </div>
        </div>
    )
}

export default Header;
