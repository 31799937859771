import { Radio, withStyles } from '@material-ui/core'

export default withStyles({
    root: {
        color: '#fff',
    },
    colorSecondary: {
        color: '#fff',
        '&$checked': {
            color: '#fff',
        },
    },
    checked: {
        color: '#fff'
    }
})(Radio)
