import { Action } from 'redux'
import { USER_NEWSLETTER_SUBSCRIBTION_SUCCESS } from '../action-types'

const initialState = {}

function rootReducer(state = initialState, action: Action) {
    switch (action.type) {
        case USER_NEWSLETTER_SUBSCRIBTION_SUCCESS:
            return {
                ...state,
                userSubscribed: true,
            }

        default:
            return state
    }
}

export default rootReducer
