import React from 'react'

import './input.scss';
import {Box} from '@material-ui/core';

type TInputProps = {
  value: string;
  label?: string;
  type?: string;
  placeholder?: string;
  reference?: React.RefObject<HTMLInputElement>;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Input = (props: TInputProps) => {
  const { onChange, value, label, placeholder, type, reference } = props;
  const Field = (
    <input
      ref={reference}
      placeholder={placeholder}
      className="input"
      type={type || 'text'}
      onChange={onChange} value={value}
    />
  );

  if (label) {
    return (
      <label className="label">
        <Box mb={2} className="input__label">{label}</Box>
        {Field}
      </label>
    );
  }

  return Field;
}

export default Input;
