import React from 'react'

import './social-button.scss'

export enum ESocialButtonType {
    FACEBOOK = 'facebook',
    LINKEDIN = 'linkedin',
    INSTAGRAM = 'instagram',
    YOUTUBE = 'youtube',
}

export type TSocialButtonType =
    | ESocialButtonType.FACEBOOK
    | ESocialButtonType.LINKEDIN
    | ESocialButtonType.INSTAGRAM
    | ESocialButtonType.YOUTUBE

type TSocialButtonProps = {
    url: string;
    type: TSocialButtonType;
}

const SocialButton = (props: TSocialButtonProps) => {
    const { url, type } = props

    return (
        <a className="social-button" rel="noreferrer" target="_blank" href={url} >
            <span className={`social-icon social-icon__${type}`} />
        </a>
    )
}

export default SocialButton
