import React from 'react'

import './content.scss';


const Content = () => (
  <div className="content text-center">
    <h1 className="content__title">Interested to tune in with angels and deliver high-end pro-creative artistic output?</h1>
    <p className="content__description">Join our newsletter <br/> or follow us on social media.<br/> Be the first to
      hear exciting news and updates!<br/> Don’t worry, that’s all we’ll email you about.</p>
  </div>
)

export default Content;
