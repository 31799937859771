import React from 'react'
import logo from '../../images/logo.png';

import './logo.scss';

const Logo = () => (
  <div className="text-center">
    <img src={logo} alt="A&A" className="logo"/>
    <div className="logo__title">ARTISTS&ANGELS</div>
  </div>
)

export default Logo;
