import axios from 'axios'

export const axiosConfig = axios.create({
    baseURL: 'https://artistsangels.art/',
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    },
})

export default axiosConfig
