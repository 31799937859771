import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import axiosInstance from '../configs/axios'

import rootReducer from './rootReducer'
import initialState from './initialState'

const store = createStore(rootReducer, initialState, applyMiddleware(thunk))

axiosInstance.interceptors.request.use(
    (response) => {
        return response
    },
    (error: any) => {
        return Promise.reject(error)
    },
)

export default store
