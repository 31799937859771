import { Dispatch } from 'redux'
import { axiosConfig } from '../../configs/axios'
import { TType } from '../../components/Form/form'

export type TFormData = {
    email: string;
    type: TType;
    arts: string;
}

const responseConverter = (responseMessage: string | undefined) => {
    if (!responseMessage) {
        return ''
    }

    if (responseMessage.includes('Duplicate entry') && responseMessage.includes("for key 'email_address'")) {
        return 'Email address already in use'
    }

    return responseMessage.split('<br>')[1]
}

export const saveUserdata = (data: TFormData) => {
    return async (dispatch: Dispatch) => {
        try {
            const formData = new FormData()

            formData.append('email', data.email)
            formData.append('type', data.type)
            formData.append('arts', data.arts)
            formData.append('action', 'save')

            const response = await axiosConfig.post('/ajax_handler.php', formData)

            if(response.data.includes('New record created successfully')){
                return { status: response.status, message: 'success' };
            }

            if (response.data.includes('Error')) {
                return { status: 404, message: responseConverter(response.data) }
            }

            return { status: response.status, message: 'success' }
        } catch (error: any) {
            console.error(error)
            if (Array.isArray(error.response.data.message)) {
                return { status: 404, message: error.response.data.message.join(', ') }
            } else {
                return { status: 404, message: error.response.data.message}
            }
        }
    }
}

export const unsubscribeUser = (email: string) => {
    return async () => {
        try {
            const formData = new FormData()

            formData.append('email', email)
            formData.append('action', 'unsubscribe')

            const response = await axiosConfig.post('/ajax_handler.php', formData)

            if (response.data.includes('Error')) {
                return { status: 404, message: responseConverter(response.data) }
            }

            return { status: response.status, message: 'success' }
        } catch (error: any) {
            console.error(error)
            return { status: 404, message: error.response.data.message.join(', ') }
        }
    }
}
