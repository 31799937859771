export const selectOptions = [
    'Culinary Arts',
    'Dancing',
    'Decorative Arts',
    'Feminity',
    'Film',
    'Healing Arts',
    'Martial Arts',
    'Music',
    'Photography',
    'Singing',
    'Theater/Acting',
    'Visual Arts',
    'Water Arts',
    'Writing',
    'Art of Woodworking',
    'Circus Arts',
    'Fashion',
    'Gardening',
    'Literary Arts',
].sort();

export const radioOptions = [
    { label: 'Teach', value: 'Teach' },
    { label: 'Learn', value: 'Learn' },
]