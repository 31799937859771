import * as React from 'react'
import RadioButton from '../RadioButton/RadioButton'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import './radio-buttons.scss'
import { Box } from '@material-ui/core'

type OptionValue = { value: string; label: string }

type TProps = {
    options: Array<OptionValue>;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RowRadioButtonsGroup = (props: TProps) => {
    const { options, value, onChange } = props

    return (
        <FormControl>
            <RadioGroup value={value} row onChange={onChange}>
                {options.map((o: OptionValue) => (
                    <Box mr={2} key={o.value}>
                        <FormControlLabel className="rb-label" value={o.value} control={<RadioButton
                            checkedIcon={<span className="radio__icon radio__icon-checked" />}
                            icon={<span className="radio__icon" />} />} label={o.label} />
                    </Box>
                ))}
            </RadioGroup>
        </FormControl>
    )
}

export default RowRadioButtonsGroup
